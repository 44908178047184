import axios from "axios";
import dayjs from "dayjs";

export const getAppealNumberForRestParam = (appealNumber, countyKey) =>
  countyKey + appealNumber.trim();

export const encodeIfNecessary = (url) => {
  if (url.includes("@")) {
    const pieces = url.split('/public/');
    if (pieces.length === 2) {
      pieces[1] = encodeURIComponent(pieces[1]);
      return pieces.join('/public/');
    }
  }
  return url;
};


export const addAppealEvent = async (appealNumber, countyId, eventText) => {
  if (!countyId || !appealNumber || !eventText ) {
    const error = "***** call to add event missing required parameter. No event logged";
    console.log(error);
    throw error;
  }

  return axios
    .post(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/${countyId}/appeals/${encodeURIComponent(appealNumber.trim())}/events`,
      {
        eventText: eventText
      },
      {
      }
    );
};


