import {Amplify} from 'aws-amplify';
import { signOut } from 'aws-amplify/auth';
import aws_exports from '../aws-exports';
import axios from "axios";
import {
  ADMIN,
  ASSESSOR,
  AUTHORIZED_ATTORNEY,
  COUNTY_ADMIN,
  COUNTY_CLERK, COUNTY_COMMISSIONER,
  SOLICITOR
} from "../pages/DefaultPages/common/UserRoles";
import {getCurrentUserAndGroups, getJWTToken} from "../pages/DefaultPages/common/UserUtils";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {RestStatus} from "./appealDetail";
// import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

Amplify.configure(aws_exports);

axios.interceptors.request.use( async (request) => {
  const token = await getJWTToken();
  if (token) {
    request.headers.Authorization = token;
  } else {
    await signOut();
  }
  return request;
}, null, {runWhen:(config) => config.url.startsWith(process.env.REACT_APP_API_URL)});


//const _setLoading = createAction(`${NS}SET_LOADING`)

// const poolData = {UserPoolId: aws_exports.aws_user_pools_id, ClientId: aws_exports.aws_user_pools_web_client_id};
// const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
// const cognitoUser = userPool.getCurrentUser();

// if (cognitoUser != null) {
//   try{
//     const userGroups = cognitoUser.getSession( (err, session) => {return session;} ).getIdToken().decodePayload()["cognito:groups"];
//     cognitoUser.getSession((err, session) => {
//       if (err) {
//         console.log(err);
//       } else if (!session.isValid()) {
//         console.log("Invalid session.");
//       } else {
//         console.log("IdToken: " + session.getIdToken().getJwtToken());
//       }
//     });    
//   } catch(err){
//     window.location.reload()
//   }
// };

//console.log(process.env);

// export const setLoading = isLoading => {
//   const action = _setLoading(isLoading)
//   action[pendingTask] = isLoading ? begin : end
//   return action
// }
const cognitoUserChanged = (prevCognitoUser, newCognitoUser) => newCognitoUser?.username !== prevCognitoUser?.username;

export const setUser = createAsyncThunk(
  'app/setUser',
  async (user,{getState, dispatch, requestId}) => {
    //dispatch(clearUser());
    if (!user) {
      user = await getCurrentUserAndGroups();
    }
    if (!user) {
      return {user:null, aatResponse:null};
    }
    const response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/userconfig`,
      );
    console.log("Get AAT Response", response);
    dispatch(fetchUserPreferences({username: user.cognitoUser.username, source:requestId}));
    return {user: user, aatResponse: response.data};
  },
  {
    condition: (user, {getState, dispatch}) => {
      const state = getState();
      const userState = selectUserState(state);
      const theUser = user || getCurrentUserAndGroups();
      //todo: handle no login
      if (!theUser || cognitoUserChanged(userState.cognitoUser, theUser.cognitoUser)) {
        return !selectIsUserLoading(state);
      }
      return !selectIsUserLoaded(state);
    }
  });

export const fetchUserPreferences = createAsyncThunk(
  'app/fetchUserPreferences',
  async({username, source}, {getState}) => {
    const state = getState();
    const userState = selectUserState(state);
    const response = await axios
    .get(
      `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/userconfig/contactdefaults`,
      {
      }
    );
    return response.data;
  }
);

export const saveUserPreferences = createAsyncThunk(
  'app/saveUserPreferences',
  async({contact, additionalContact, solicitorContact}, {getState, dispatch, requestId}) => {
    const state = getState();
    const username = selectUserName(state);
    const response = await axios
        .put(
          `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/userconfig/contactdefaults`,
          {
              contact: contact,
              additionalContact: additionalContact,
              solicitorContact: solicitorContact,
          },
        );
    dispatch(fetchUserPreferences({username, source:requestId}));
    return response.data;
  }
);

export const logUserActivity = createAsyncThunk(
  'app/logUserActivity',
  async({data, appeal, stage, button}, {getState, dispatch, requestId}) => {
    const state = getState();
    const username = selectUserName(state);
    const response = await axios
      .post(`${process.env.REACT_APP_API_URL }/${process.env.REACT_APP_STAGE}/v2/useractivity`, {
          appeal,
          data,
          stage,
          button
        },
        {
        })
    return response.data;
  }

);
//Clean up contact across the system is in progress: As an interim step, convert contacts from preferences on the fly until the entire table is converted.
const convertToStandardContact = (nonstandardContact, prefix, notifyPrefix) => {
  const prefixLength = prefix.length;
  return Object.entries(nonstandardContact).reduce((contact, [attribute, value]) => {
    let standardAttribute = attribute;
    if (notifyPrefix + "PhoneCheckbox" === attribute) {
      standardAttribute = "notifyBySMS";
    } else if (notifyPrefix + "EmailCheckbox" === attribute) {
      standardAttribute = "notifyByEmail";
    } else if (prefixLength && attribute.startsWith(prefix)) {
      standardAttribute = attribute.substring(prefixLength, prefixLength + 1).toLowerCase() + attribute.substring(prefixLength + 1);
    }
    contact[standardAttribute] = value;
    return contact;
  }, {});
}

const initialState = {
  // APP STATE
  from: '',
  isUpdatingContent: false,
  isLoading: false,
  dialogForms: {},
  contentBuffer: {
    pathName: null,
    content: null,
  },
  menuData:[],

  // LAYOUT STATE
  layoutState: {
    isMenuTop: false,
    menuMobileOpened: false,
    menuCollapsed: false,
    menuShadow: true,
    themeLight: true,
    squaredBorders: false,
    borderLess: true,
    fixedWidth: false,
    settingsOpened: false,
    refreshNeeded: false,
  },

  // USER STATE
  userState: {
    cognitoUser: null,
    user: {},
    userGroups: [],
    aatLoaded: false,
    isAdmin: false,
    isCountyAdmin: false,
    isAssessor: false,
    isSolicitor: false,
    isCountyClerk: false,
    isAuthorizedAttorney: false,
  }
}

export const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    _setLoading: (state, isLoading) => {
      state.isLoading = action.payload;
    },
    setUpdatingContent: (state, isUpdatingContent) => {
      state.isUpdatingContent = action.payload
    },
    setLayoutState: (state, action) => {
      const layoutState = {...state.layoutState, ...action.payload};
      window.localStorage.setItem('app.layoutState', JSON.stringify(layoutState));
      state.layoutState = layoutState;
    },
    setPathName: (state, action) => {
      state.pathName = action.payload;
    },
    setMenuData: (state, action) => {
      state.menuData = action.payload;
    },
    setForceRefresh: (state) => {
      state.refreshNeeded = true;
    },
    clearUser: (state) => {
      state.userState = initialState.userState;
      state.userPreferences = null;
      state.userPreferencesRestStatus = {status:null}
      state.userPreferencesError = '';
    },
    setNetworkError: (state, action) => {
      state.networkError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(setUser.pending, (state, action) => {
      state.userState.aatLoading = true;
      state.fetchAppealError = '';
    })
    .addCase(setUser.fulfilled, (state, action) => {
      const {user, aatResponse} = action.payload;
      if (user) {
        const {userGroups} = user;
        const responseUser = aatResponse?.user || {};
        state.userState = {
          ...user,
          user: responseUser,
          county: responseUser.County,
          municipalities: responseUser.Municipalities,
          aatLoaded: true,
          aatLoading: false,
          isCountyAdmin: userGroups.includes(COUNTY_ADMIN),
          isCountyClerk: userGroups.includes(COUNTY_CLERK),
          isCountyCommissioner: userGroups.includes(COUNTY_COMMISSIONER),
          isAssessor: userGroups.includes(ASSESSOR),
          isSolicitor: userGroups.includes(SOLICITOR),
          isAdmin: userGroups.includes(ADMIN),
          isAuthorizedAttorney: userGroups.includes(AUTHORIZED_ATTORNEY),
        };
      } else {
        state.userState = initialState.userState;
      }
    })
    .addCase(setUser.rejected, (state, action) => {
      state.userState.aatLoaded = false;
      state.userState.aatLoading = false;
      state.userState.aatError = action.error.message;
    })
    .addCase(fetchUserPreferences.pending, (state, action) => {
      state.userPreferences = null;
      state.userPreferencesRestStatus = {
        status: RestStatus.LOADING,
        source: action.meta.arg.source,
        requestId: action.meta.requestId
      };
      state.userPreferencesError = '';
    })
    .addCase(fetchUserPreferences.fulfilled, (state, action) => {
      const preferences = action.payload;
      const {contactInfo} = preferences;
      if (contactInfo) {
        const {contact} = contactInfo;
        if (Object.hasOwn(contact,'mainEmailCheckbox')) {
          contactInfo.contact = convertToStandardContact(contact, '', 'main');
        }
        const {additionalContact} = contactInfo;
        if (Object.hasOwn(additionalContact,'additionalFirstName')) {
          contactInfo.additionalContact = convertToStandardContact(additionalContact, 'additional', 'contact');
        }
        const {solicitorContact} = contactInfo;
        contactInfo.solicitorContact = solicitorContact || {isActive:false};
      }
      state.userPreferences = preferences;
      state.userPreferencesRestStatus.status = RestStatus.LOADED;
    })
    .addCase(fetchUserPreferences.rejected, (state, action) => {
      state.userPreferencesRestStatus.status = RestStatus.LOAD_FAILED;
      state.userPreferencesError = action.error.message;
    })
    .addCase(saveUserPreferences.pending, (state, action) => {
      state.userPreferencesRestStatus = {
        status: RestStatus.Saving,
        requestId: action.meta.requestId
      };
      state.userPreferencesError = '';
    })
    .addCase(saveUserPreferences.fulfilled, (state, action) => {
      state.userPreferencesRestStatus.status = RestStatus.SAVED;
    })
    .addCase(saveUserPreferences.rejected, (state, action) => {
      state.userPreferencesRestStatus.status = RestStatus.SAVE_FAILED;
      state.userPreferencesError = action.error.message;
    })
    .addCase(logUserActivity.pending, (state, action) => {
      state.userActivityRestStatus = {
        status: RestStatus.Saving,
        requestId: action.meta.requestId
      };
    })
    .addCase(logUserActivity.fulfilled, (state, action) => {
      state.userActivityRestStatus.status = RestStatus.SAVED;
    })
    .addCase(logUserActivity.rejected, (state, action) => {
      state.userActivityRestStatus.status = RestStatus.SAVE_FAILED;
      state.userActivityRestStatus.error = action.error.message;
      state.networkError = "Log user activity failed: " + action.error.message;
    })
  }
});

export const selectUserState = state => state.app.userState;
export const selectUserCounty = state => state.app.userState.county;
export const selectUserMunicipalities = state => state.app.userState.municipalities;
export const selectIsUserLoading = state => state.app.userState.aatLoading;
export const selectIsUserLoaded = state => state.app.userState.aatLoaded;
export const selectUser = state => state.app.userState.user
export const selectUserName = state => state.app.userState.cognitoUser?.username;
export const selectUserGroups = state => state.app.userState.userGroups || [];
export const selectUserIsAdmin = state => state.app.userState.isAdmin;
export const selectUserIsAssessor = state => state.app.userState.isAssessor;
export const selectUserIsAuthorizedAttorney = state => state.app.userState.isAuthorizedAttorney;
export const selectUserIsCountyAdmin = state => state.app.userState.isCountyAdmin;
export const selectUserIsCountyClerk = state => state.app.userState.isCountyClerk;
export const selectUserIsCountyCommissioner = state => state.app.userState.isCountyCommissioner;
export const selectUserIsSolicitor = state => state.app.userState.isSolicitor;
export const selectUserIsAppellant = state => !state.app.userState?.userGroups?.length;
export const selectCognitoUserEmail = state => state.app.userState.cognitoUser?.email;
export const selectCognitoUserPhone = state => state.app.userState.cognitoUser?.phoneNumber;
export const selectUserPreferences = state => state.app.userPreferences;
export const selectUserPreferencesRestStatus = state => state.app.userPreferencesRestStatus;
export const selectUserPreferencesError = state => state.app.userPreferencesError;
export const selectMenuData = state => state.app.menuData;
export const selectPathName = state => state.app.pathName;
export const selectNetworkError = state => state.app.networkError;
export const selectLayoutState = state => state.app.layoutState;

export const {setUpdatingContent, setLayoutState,
  setForceRefresh, setPathName, setMenuData, setLoading, clearUser, setNetworkError} = appSlice.actions;

export default appSlice.reducer;

